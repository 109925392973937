import React, { useState, useEffect } from "react"
import * as R from "ramda"
import PropTypes from "prop-types"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemText from "@material-ui/core/ListItemText"
import Select from "@material-ui/core/Select"

import { makeStyles } from "@material-ui/core/styles"

import colors from "../../theme/colors"

import toTitleCase from "../../../utils/toTitleCase"
import searchBoxConfiguration from "../../../data/search-box-configuration.json"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0.5rem 1rem",
  },
  clickable: {
    cursor: "pointer",
  },
  alignLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  alignRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  fields: {
    display: "flex",
    alignItems: "center",
  },
  inputBox: {
    /* Hacking in style override for the moment */
    minWidth: "200px", //TODO This is a style hack & needs fixed
    flexGrow: 1,
    border: `1px solid ${colors.blue[1]}`,
    borderRadius: "6px 0 6px 6px !important",
    padding: "0 0.25rem",
    "& .MuiInputBase-input": {
      padding: "0.25em 0em !important",

      "& .MuiListItemText-root": {
        marginTop: "0",
        marginBottom: "0",
      },
    },
    "& svg": {
      height: "0.6em",
      width: "0.6em",
    },
  },
  rangeIcon: {
    fontSize: "1.5rem",
  },
  messageContent: {
    padding: "0.25em 1em",
  },
  outgoingMessage: {
    color: colors.base.white,
    backgroundColor: colors.blue[1],
    border: `1px solid ${colors.blue[1]}`,
    borderRadius: "6px 0 6px 6px",
    "&$clickable": {
      "&:hover": {
        borderColor: colors.blue[0],
        background: colors.blue[0],
      },
    },
  },
}))

const ChatDropdownInput = ({ onClick, type, placeholder, options }) => {
  const [value, setValue] = useState("")
  const [clicked, setClicked] = useState(false)
  const clickable = !!onClick

  const handleChange = e => {
    setValue(e.target.value)
  }

  const handleSubmit = () => {
    if (!value || value === "") return

    if (!clicked) {
      setClicked(true)

      const valueString =
        value.min && value.max
          ? `${value.min} - ${value.max} hlö`
          : !value.min && value.max
          ? `< ${value.max} hlö`
          : value.min && !value.max
          ? `${value.min}+ hlö`
          : ""

      if (!!onClick) {
        onClick(
          {
            workspaces: {
              ...value,
            },
          },
          valueString
        )
      }
    }
  }

  const classes = useStyles()

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={1}
      className={classes.root}
    >
      <Grid item xs={9} className={classes.fields} style={{ flexGrow: 1 }}>
        <Select
          className={classes.inputBox}
          value={value}
          onChange={handleChange}
          margin="dense"
          variant="outlined"
          displayEmpty
          IconComponent={ExpandMoreIcon}
          MenuProps={{
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">hlö</InputAdornment>,
          }}
        >
          <MenuItem key={"wspaces-placeholder"} value={""} disabled>
            <ListItemText
              className={`${classes.selectItem} ${classes.placeholder}`}
              primary={R.path(
                ["workspaceAmountPlaceholder"],
                searchBoxConfiguration
              )}
            />
          </MenuItem>
          {R.keys(
            R.pathOr({}, ["workspaceAmount"], searchBoxConfiguration)
          ).map((key, idx) => (
            <MenuItem
              key={`wspaces-${idx}`}
              value={R.path(
                ["workspaceAmount", key, "value"],
                searchBoxConfiguration
              )}
            >
              <ListItemText
                className={classes.selectItem}
                primary={toTitleCase(
                  R.path(
                    ["workspaceAmount", key, "label"],
                    searchBoxConfiguration
                  )
                )}
              />
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={3}>
        <Box onClick={handleSubmit}>
          <Box
            className={`${classes.messageBox} ${classes.outgoingMessage} ${
              clickable && !clicked ? classes.clickable : ""
            }`}
          >
            <Box className={classes.messageContent}>OK</Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

ChatDropdownInput.propTypes = {
  direction: PropTypes.oneOf(["outgoing", "incoming"]).isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default ChatDropdownInput
