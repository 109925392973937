import React from "react"

import Typography from "@material-ui/core/Typography"

import ChatMessage from "../ChatMessage"

export const WhatType = () => {
  return [
    <ChatMessage direction="incoming">
      <Typography variant="body2" component="span">
        Millaista tilaa etsit?
      </Typography>
    </ChatMessage>,
  ]
}

export const WhatTypeInput = onClick => {
  return [
    { label: "Toimisto", value: "toimistot" },
    { label: "Liiketila", value: "liiketilat" },
    { label: "Varasto", value: "varastot" },
    { label: "Tuotantotila", value: "tuotantotilat" },
  ].map(type => {
    return (
      <ChatMessage
        direction="outgoing"
        onClick={() => onClick({ filters: { type: type.value } }, type.label)}
      >
        <Typography variant="body2" component="span">
          {type.label}
        </Typography>
      </ChatMessage>
    )
  })
}
