// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookies-js": () => import("./../../../src/pages/cookies.js" /* webpackChunkName: "component---src-pages-cookies-js" */),
  "component---src-pages-hoivatilat-js": () => import("./../../../src/pages/hoivatilat/[...].js" /* webpackChunkName: "component---src-pages-hoivatilat-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-liiketilat-js": () => import("./../../../src/pages/liiketilat/[...].js" /* webpackChunkName: "component---src-pages-liiketilat-js" */),
  "component---src-pages-ota-yhteytta-js": () => import("./../../../src/pages/ota-yhteytta.js" /* webpackChunkName: "component---src-pages-ota-yhteytta-js" */),
  "component---src-pages-palvelut-js": () => import("./../../../src/pages/palvelut.js" /* webpackChunkName: "component---src-pages-palvelut-js" */),
  "component---src-pages-tarinat-js": () => import("./../../../src/pages/tarinat.js" /* webpackChunkName: "component---src-pages-tarinat-js" */),
  "component---src-pages-toimistot-js": () => import("./../../../src/pages/toimistot/[...].js" /* webpackChunkName: "component---src-pages-toimistot-js" */),
  "component---src-pages-toimitilat-js": () => import("./../../../src/pages/toimitilat/[...].js" /* webpackChunkName: "component---src-pages-toimitilat-js" */),
  "component---src-pages-tuotantotilat-js": () => import("./../../../src/pages/tuotantotilat/[...].js" /* webpackChunkName: "component---src-pages-tuotantotilat-js" */),
  "component---src-pages-varastot-js": () => import("./../../../src/pages/varastot/[...].js" /* webpackChunkName: "component---src-pages-varastot-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-premises-js": () => import("./../../../src/templates/premises.js" /* webpackChunkName: "component---src-templates-premises-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-service-offering-js": () => import("./../../../src/templates/service-offering.js" /* webpackChunkName: "component---src-templates-service-offering-js" */)
}

