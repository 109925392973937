import React from "react"

import Typography from "@material-ui/core/Typography"

import ChatMessage from "../ChatMessage"

export const Blank = () => {
  return [<React.Fragment />]
}

export const Single = text => {
  return [
    <ChatMessage direction="outgoing">
      <Typography variant="body2" component="span">
        {text}
      </Typography>
    </ChatMessage>,
  ]
}
