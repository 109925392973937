import React from "react"
import * as R from "ramda"

import Typography from "@material-ui/core/Typography"

import ChatMessage from "../ChatMessage"
import ChatTextInput from "../inputs/ChatTextInput"

import submitLead from "../../../api/submitLead"

export const LeaveContact = () => {
  return [
    <ChatMessage direction="incoming">
      <Typography variant="body2" component="span">
        Haluatko, että otamme sinuun yhteyttä? Katsotaan tilaratkaisusi yhdessä
        kuntoon.
      </Typography>
    </ChatMessage>,
  ]
}

export const LeaveContactInput = (onClick, chatState) => {
  const holder = R.isEmpty(chatState.filters) ? "Sähköposti, sijainti, tilatyyppi, tilan koko" : "Sähköposti"; //"nimi@email.com"; 
  return [
    <ChatTextInput
      direction="outgoing"
      onClick={async email => {

        // Datalayer push on contact input submit
        const dataLayerObject = {
          'event':'chatbotEmail',
          'eventAction':'chatbotEmailSubmitted',
        };

        // Push datalayer object
      if (typeof window !== 'undefined') {
        if(window.dataLayer){
          if(window.dataLayer.length > 0 && !R.equals(window.dataLayer[window.dataLayer.length - 1], dataLayerObject)){
            // eslint-disable-next-line no-undef
            window.dataLayer.push(dataLayerObject);
          }else if(window.dataLayer.length === 0){
            // eslint-disable-next-line no-undef
            window.dataLayer.push(dataLayerObject);
          }
        }else{
          window.dataLayer = [];
          // eslint-disable-next-line no-undef
          window.dataLayer.push(dataLayerObject);
        }
      }

        // Send lead to API
        await submitLead({
          form: {
            email: email.split(",")[0],
            message: email.split(",").length > 1 ? email.split(",").splice(1, email.split(",").length) + "" : "",
          },
          meta: {
            chat: chatState,
          },
        })

        //Progress chat area
        onClick({ submittedContact: true }, email)
      }}
      placeholder={holder}
    ></ChatTextInput>,
  ]
}

export const LeaveContactThankYou = () => {
  return [
    <ChatMessage direction="incoming">
      <Typography variant="body2" component="span">
        Kiitos yhteydenotostasi! Olemme sinuun yhteydessä.
      </Typography>
    </ChatMessage>,
  ]
}
