import React, { useState, useContext, useEffect } from "react"
import * as R from "ramda"
import { useStaticQuery, graphql } from "gatsby"

import SearchContext from "./SearchContext"
import * as ChatLogic from "../components/chatbot/ChatLogic"

import { putToSessionStorage, fetchFromSessionStorage } from "../utils/storage"

const defaultState = {
  messages: [],
  userInputs: [],
  unreadMessages: 0,
  chatState: {},
  isChatOpen: () => {},
  addMessages: () => {},
  reset: () => {},
}

const ChatbotContext = React.createContext(defaultState)

const ChatbotProvider = props => {
  const initalChatState = {
    ready: false,
    intro: false,
    completed: false,
    triggers: {},
    filters: {},
  }
  const data = useStaticQuery(graphql`
    query ChatbotQuery {
      allNewsecPremisesCityAreas {
        nodes {
          city
          areas
        }
      }
    }
  `)

  const searchContext = useContext(SearchContext)
  const [searchLocations] = useState(
    R.pathOr([], ["allNewsecPremisesCityAreas", "nodes"], data)
  )

  const [messages, setMessages] = useState([])
  const [pendingMessages, setPendingMessages] = useState([])
  const [unreadMessages, setUnreadMessages] = useState(0)
  const [chatState, setChatState] = useState(initalChatState)
  const [userInputs, setUserInputs] = useState([])
  const [chatOpen, setChatOpen] = useState(false)

  const interactionDelay = 5000

  /* Kick things off when the Chatbot is rendered * /
  useEffect(() => {
    const timer = setTimeout(() => startChat(), interactionDelay);
    return () => clearTimeout(timer);
  }, []);*/

  useEffect(() => {
    if (chatOpen) {
      setUnreadMessages(0)
      startChat() // Start chat will check if chat needs to be initiated.
    }
  }, [chatOpen])

  useEffect(() => {
    if (chatState.ready) {
      progressChat()
    }
  }, [chatState])

  useEffect(() => {
    setMessages([...messages, ...pendingMessages])
    if (!chatOpen) {
      setUnreadMessages(unreadMessages + pendingMessages.length)
    }
  }, [pendingMessages])

  const addMessages = newMsgs => {
    setPendingMessages(newMsgs)
  }

  const startChat = () => {
    setChatState({
      ...chatState,
      ready: true,
    })
  }

  const handleInput = (value, text) => {
    // Load on interaction on two first buttons (Kyllä/Ei)
    if (!!text && text === 'Ei' || text === 'Kyllä') {
      if (typeof window !== 'undefined') {
        if(!window.dataLayer){
          window.dataLayer = [];
        }
        
        window.dataLayer.push({
          event : "firstInteraction",
          buttonText : text	
        });
      }
    }

    const { messages, inputs, newState } = ChatLogic.HandleUserChoice(
      chatState,
      value,
      text
    )

    if (!!messages) {
      addMessages(messages)
    }
    if (!!inputs) {
      setUserInputs(inputs)
    }
    if (!!newState) {
      setChatState({
        ...chatState,
        ...newState,
      })
    }
  }

  const progressChat = () => {
    const { messages, inputs, newState } = ChatLogic.GetNext(
      chatState,
      handleInput,
      searchLocations,
      searchContext.execSyncSearch
    )
    if (!!messages) {
      addMessages(messages)
    }
    if (!!inputs) {
      setUserInputs(inputs)
    }
    if (!!newState) {
      setChatState({
        ...chatState,
        ...newState,
      })
    }
  }

  const isChatOpen = isOpen => {
    setChatOpen(!!isOpen)
    putToSessionStorage('ChatBot_opened', true, false);
  }

  const reset = () => {
    //setMessages([]);
    setUnreadMessages(0)
    setChatState({
      ready: true,
    })
  }

  return (
    <ChatbotContext.Provider
      value={{
        messages,
        userInputs,
        unreadMessages,
        chatState,
        isChatOpen: isOpen => isChatOpen(isOpen),
        addMessages: newMsgs => addMessages(newMsgs),
        reset: () => reset(),
      }}
    >
      {props.children}
    </ChatbotContext.Provider>
  )
}

export default ChatbotContext

export { ChatbotProvider }
