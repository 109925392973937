import axios from "axios" // For IE support

const url = process.env.GATSBY_APP_AWS_API
const key = process.env.GATSBY_APP_AWS_API_KEY

export const submitLead = async data => {
  try {
    return await axios.post(
      `${url}/crmlead`,
      {
        data,
      },
      {
        headers: {
          "x-api-key": key,
        },
      }
    )
  } catch (err) {
    throw err
  }
}

export const submitGenericLead = async data => {
  try {
    return await axios.post(
      `${url}/crmlead`,
      {
        data,
      },
      {
        headers: {
          "x-api-key": key,
        },
      }
    )
  } catch (err) {
    throw err
  }
}

export const getLatLng = async ({ streetAddress, postcode, city }) => {
  try {
    const res = await axios.post(
      `${url}/coords`,
      {
        streetAddress: streetAddress || "",
        postcode: postcode || "",
        city: city || "",
      },
      {
        headers: {
          "x-api-key": key,
        },
      }
    )
    if (res.data) {
      return res.data
    } else {
      return {}
    }
  } catch (err) {
    return {}
  }
}

export const getMediaFileUrl = async name => {
  try {
    return
  } catch (err) {
    return err
  }
}

export const getDataFileUrl = async name => {
  try {
    return
  } catch (err) {
    return err
  }
}
