import { submitLead } from "./integrations/aws"

/*
Request to API should have the following shape:
{
    form: {
        name:
        company:
        email:
        phone:
        message:
        marketingPerm:

    },
    meta: {
        broker: {
            name:
            email:
        },
        source: {
            premises: {
                address
                url
            },
            search: {
                filters: {
                    [all search filters]
                }
            },
            chat: {
                filters: {
                    [filters from chat serach (if any)]
                }
            }
        }
    }
}
*/

export default async data => {
  try {
    /* The current AWS backend handles the data parsing and validation, 
        so just pass through everything. When MS Dynamics is taken into use, 
        some massaging of the data may be needed */
    await submitLead(data)
    return
  } catch (err) {
    throw err
  }
}
