import * as R from "ramda"

import { Single, Blank } from "./messages/Basic"
import { Intro, IntroInputs } from "./messages/Intro"
import { WhatType, WhatTypeInput } from "./messages/WhatType"
import {
  WhatCity,
  WhatCityInput,
  IncludeArea,
  IncludeAreaInput,
  WhatArea,
  WhatAreaInput,
} from "./messages/WhatLocation"
import {
  WhatSize,
  WhatSqmSizeInput,
  WhatWorkspaceSizeInput,
} from "./messages/WhatSize"
import {
  LeaveContact,
  LeaveContactInput,
  LeaveContactThankYou,
} from "./messages/LeaveContact"
import { Results, FoundResults, NoResults } from "./messages/Results"

export const GetNext = (
  chatState,
  handler,
  searchLocations,
  execSyncSearch
) => {
  if (!chatState.ready || chatState.finished) {
    return {}
  }

  /* This part should always be evaulated first
    so the bot introduces itself */
  if (!chatState.started) {
    if (!chatState.intro) {
      const newState = {
        ...chatState,
        intro: true,
      }
      return { messages: Intro(), inputs: IntroInputs(handler), newState }
    } else {
      /* Don't continue until chat is started */
      return {}
    }
  }

  /* Check if the chat should display the lead form */
  if (chatState.collectContact && !chatState.submittedContact) {
    /* Display the lead form */
    if (!chatState.contactForm) {
      const newState = {
        ...chatState,
        contactForm: true,
      }
      return {
        messages: LeaveContact(),
        inputs: LeaveContactInput(handler, newState),
        newState,
      }
      /* Wait for user inoput */
    } else {
      return {}
    }
  }

  /* If the lead has been submitted, display thank you message */
  if (
    chatState.collectContact &&
    chatState.submittedContact &&
    !chatState.finished
  ) {

    const dataLayerObject = {
      'event':'chatbot',
      'eventAction':'contactRequestSubmitted',
    };

    // Push datalayer object
    if (typeof window !== 'undefined') {
      if(window.dataLayer){
        if(window.dataLayer.length > 0 && !R.equals(window.dataLayer[window.dataLayer.length - 1], dataLayerObject)){
          // eslint-disable-next-line no-undef
          window.dataLayer.push(dataLayerObject);
        }else if(window.dataLayer.length === 0){
          // eslint-disable-next-line no-undef
          window.dataLayer.push(dataLayerObject);
        }
      }else{
        window.dataLayer = [];
        // eslint-disable-next-line no-undef
        window.dataLayer.push(dataLayerObject);
      }
    }

    const newState = {
      ...chatState,
      finished: true,
    }
    return { messages: LeaveContactThankYou(), newState }
  }

  /* Get information on type */
  if (!R.path(["filters", "type"], chatState)) {
    /* If the question hasn't been asked yet, show the messages and input */
    if (!chatState.type) {
      const newState = {
        ...chatState,
        type: true,
      }
      return { messages: WhatType(), inputs: WhatTypeInput(handler), newState }
      /* If not then we are waiting for user input */
    } else {
      return {}
    }
  }

  /* Get information on city */
  if (!R.path(["filters", "city"], chatState)) {
    /* If the question hasn't been asked yet, show the messages and input */
    if (!chatState.city) {
      const newState = {
        ...chatState,
        city: true,
      }
      const cities = searchLocations.map(l => l.city)
      return {
        messages: WhatCity(),
        inputs: WhatCityInput(handler, cities),
        newState,
      }
      /* If not then we are waiting for user input */
    } else {
      return {}
    }
  }

  // Get the available areas for the selected city (this is used later in the chat logic)
  const areas = searchLocations.reduce((acc, location) => {
    if (R.path(["city"], location) === R.path(["filters", "city"], chatState)) {
      return acc.concat(R.pathOr([], ["areas"], location))
    } else {
      return acc
    }
  }, [])

  /* Only check about locatiopns if there is at least 1 location for the city */
  if (areas.length > 0) {
    /* Check if to include information on area */
    if (chatState.includeArea === undefined) {
      /* Display the question if to include areas */
      if (!chatState.askIncludeArea) {
        const newState = {
          ...chatState,
          askIncludeArea: true,
        }
        return {
          messages: IncludeArea(),
          inputs: IncludeAreaInput(handler),
          newState,
        }
        /* Wait for user inoput */
      } else {
        return {}
      }
    }

    /* Get information on area */
    if (chatState.includeArea && !R.path(["filters", "area"], chatState)) {
      /* If the question hasn't been asked yet, show the messages and input */
      if (!chatState.area) {
        const newState = {
          ...chatState,
          area: true,
        }
        return {
          messages: WhatArea(),
          inputs: WhatAreaInput(handler, areas),
          newState,
        }
        /* If not then we are waiting for user input */
      } else {
        return {}
      }
    }
  }
  // Removing the workspae selection until the query string API is ready

  /* Get size requirements (based on input type it will either be for number of people or number of m2 */
  /*if (R.path(['filters','type'],chatState) === "toimistot" && 
  (!R.path(['filters','workspaces','min'],chatState) && !R.path(['filters','workspaces','max'],chatState))) {
    /* If the question hasn't been asked yet, show the messages and input * /
    if (!chatState.size) {
      const newState = {
          ...chatState,
          size: true,
      }
      return { messages: WhatSize(), inputs: WhatWorkspaceSizeInput(handler), newState}
    /* If not then we are waiting for user input * /
    } else {
      return {}; 
    }
  }

  if (R.path(['filters','type'],chatState) !== "toimistot" && */
  if (
    !R.path(["filters", "sizeMin"], chatState) &&
    !R.path(["filters", "sizeMax"], chatState)
  ) {
    /* If the question hasn't been asked yet, show the messages and input */
    if (!chatState.size) {
      const newState = {
        ...chatState,
        size: true,
      }
      return {
        messages: WhatSize(),
        inputs: WhatSqmSizeInput(handler),
        newState,
      }
      /* If not then we are waiting for user input */
    } else {
      return {}
    }
  }

  /* At this point execute a search if the serach has not been run. This will run in the main search context */
  if (!chatState.searching) {
    const newState = {
      ...chatState,
      searching: true,
    }
    // Run search here
    return { messages: Results(), newState }
  }

  if (!chatState.results) {
    const fitlers = {
      types: [R.path(["filters", "type"], chatState)],
      locations: [
        {
          city: R.path(["filters", "city"], chatState).toLowerCase(),
          ...(R.path(["filters", "area"], chatState) && {
            area: R.path(["filters", "area"], chatState).toLowerCase(),
          }),
        },
      ],
      ...(R.path(["filters", "sizeMin"], chatState) && {
        sizeMin: R.path(["filters", "sizeMin"], chatState),
      }),
      ...(R.path(["filters", "sizeMax"], chatState) && {
        sizeMax: R.path(["filters", "sizeMax"], chatState),
      }),
    }
    const filteredSpaces = execSyncSearch(fitlers)
    const queries = []
    if (R.path(["filters", "sizeMin"], chatState))
      queries.push(`smin=${R.path(["filters", "sizeMin"], chatState)}`)
    if (R.path(["filters", "sizeMax"], chatState))
      queries.push(`smax=${R.path(["filters", "sizeMax"], chatState)}`)
    if (R.path(["filters", "workspaces", "min"], chatState))
      queries.push(
        `wmin=${R.path(["filters", "workspaces", "min"], chatState)}`
      )
    if (R.path(["filters", "workspaces", "max"], chatState))
      queries.push(
        `wmax=${R.path(["filters", "workspaces", "max"], chatState)}`
      )

    const query = queries.join("&")
    // TODO make this cleaner code
    const url = R.path(["filters", "area"], chatState)
      ? `${R.path(["filters", "type"], chatState)}/${R.path(
          ["filters", "city"],
          chatState
        )}/${R.path(["filters", "area"], chatState)}?${query}`
      : `${R.path(["filters", "type"], chatState)}/${R.path(
          ["filters", "city"],
          chatState
        )}?${query}`

    if (filteredSpaces.length > 0) {
      const newState = {
        ...chatState,
        results: true,
        finished: true,
      }
      return { messages: FoundResults(filteredSpaces.length, url), newState }
    } else {
      const newState = {
        ...chatState,
        collectContact: true,
        results: true,
      }
      return { messages: NoResults(), newState }
    }
  }

  return {} //Return null to not have any updates
}

export const HandleUserChoice = (currState, value, text) => {
  const newState = R.mergeDeepLeft(currState, value)

  return {
    messages: Single(text),
    inputs: Blank(),
    newState,
  }
}
