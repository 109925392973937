import React, { useState, useEffect } from "react"
import * as R from "ramda"
import { Index } from "elasticlunr"

import searchTypes from "../data/url-search-base-types.json"

import toTitleCase from "../utils/toTitleCase"
import { putToSessionStorage, fetchFromSessionStorage } from "../utils/storage"

const isClient = typeof window !== "undefined"

const STORAGE_KEY = "tth:filters"

const defaultState = {
  siteSearchIndex: undefined,
  allPremises: [],
  filteredPremises: [],
  filters: [],
  updateFilters: () => {},
  isSearching: false,
  autocompleteFilters: [],
  loadSearchIndex: async () => {},
  resultPage: null,
  setResultPage: () => {},
  sorting: "",
  sortingOptions: [],
  setSorting: () => {},
  searchUrl: "",
  setSearchUrl: () => {},
  execSyncSearch: () => {},
  resetSearch: () => {},
  indexesLoaded: false,
  heroLoaded: false,
}

const SearchContext = React.createContext(defaultState)

const SearchProvider = props => {

  let ssi = undefined;
  let ssiPremises = undefined;
  let ssiAutocomplete = undefined;

  const [siteSearchIndex, setSearchIndex] = useState(undefined)
  const [allPremises, setAllPremises] = useState([])
  const [autocompleteFilters, setAutocompleteFilters] = useState([])

  const [heroLoaded, setHeroLoaded] = useState(false)
  const [indexesLoaded, setIndexesLoaded] = useState(false)
  const [index1Loaded, setIndexes1Loaded] = useState(false)
  const [index2Loaded, setIndexes2Loaded] = useState(false)
  const [index3Loaded, setIndexes3Loaded] = useState(false)

  const [preload, setPreload] = useState(false);

  const preloadIndex = (preload) => {
    setPreload(preload);
  }

  useEffect(() => {
    (index1Loaded && index2Loaded && index3Loaded) && setIndexesLoaded(true)
  }, [index1Loaded, index2Loaded, index3Loaded]);

  useEffect(() => {
    async function fetchSSI() {
      let response = await fetch('/siteSearchIndex.json')
      response = await response.json()
      ssi = Index.load(response)
      setSearchIndex(ssi);
      loadSearchIndex();
      setIndexes1Loaded(true)
    }

    (preload && !index1Loaded) && fetchSSI()
  }, [preload]);

  useEffect(() => {
    /*
      The effects with empty array [] will be called only at the beginning and the end of components life.
      This is used to fetch all premises and set them to allPremises. This makes sure that allPremises always contains all premises for the chatbot to use.
    */
    async function fetchPremises() {
      let response = await fetch('/searchPremises.json')
      response = await response.json()
      ssiPremises = R.pathOr([], ["nodes"], response)
      setAllPremises(ssiPremises);
      setIndexes2Loaded(true)
    }
    fetchPremises()
  }, []);

  useEffect(() => {
    async function fetchPremises() {
      let response = await fetch('/searchPremises.json')
      response = await response.json()
      ssiPremises = R.pathOr([], ["nodes"], response)
      setAllPremises(ssiPremises);
      setIndexes2Loaded(true)
    }
    (preload && !index2Loaded) && fetchPremises()
  }, [preload]);

  useEffect(() => {
    async function fetchAutocomplete() {
      let response = await fetch('/searchPremisesAutoComplete.json');
      response = await response.json();
      ssiAutocomplete = R.pathOr([], ["nodes"], response);
      setAutocompleteFilters(ssiAutocomplete);
      setIndexes3Loaded(true);
    }
    (preload && !index3Loaded) && fetchAutocomplete();
  }, [preload]);
  
  /*
    This is purely here to help the "back" button when viewing a space page & 
    so it can be used with Gatsby Link.
  */
  const [searchUrl, setSearchUrl] = useState()

  /* 
    This is the master list of all premises for the app that is never changed 
    so the context can always start filtering everything  from the start
  */
  

  // Free text search index, loaded when needed (& only in browsers)
  
  const [elasticLunrSearch, setElasticLunrSearch] = useState()

  /*
    The format of the filters are determined by the form used in the SearchBox component. 
    This context is used to persist search form submissions across pages, so the format of the filters 
    just needs to replicate the SearchBox form.values (hence the quite flat structure).

    The filters are used in the following way when searching for available premises:

    ~~~ TYPES ~~~
    
    If the TYPES filter has only one filter (e.g. ["toimistot"] the returned premises must match the TYPE.
    If more than one TYPE is set, premises must mnatch BOTH types to be returned as results.

    ~~~ LOCATIONS ~~~
    Premises are also filtered by locations (which contain any one of city, area and postcode). Results 
    are filtered by checking if a premises match any one of the locations specified (e.g. searching for 
    helsinki and tampere will return premises is either helisnki or tampere)


    {
      types: [], 
      locations: [
        {
          city: "",
          postcode: "",
          area: "",
          streetAddress: ""
        }
      ],
      workspaces: {
        mix: 0,
        max: 0
      },
      sizeMin: 0,
      sizeMax: 0,
        min: 0,
        max: 0
      }

    }

    

  */

  /* Before initialising filters, check if there is any filter state in session storage that needs to be restored */
  const storedFilters = fetchFromSessionStorage(STORAGE_KEY, true)
  const [filters, setFilters] = useState(storedFilters || {})

  const [filteredPremises, setFilteredPremises] = useState(allPremises) // Run filters??

  const [isSearching, setIsSearching] = useState(false)

  /* Result pages and sorting */
  const [resultPage, setResultPage] = useState(null)
  const sortingOptions = [
    { label: "Suositit", value: "recommended" },
    { label: "Newest", value: "newest" },
    { label: "Oldest", value: "oldest" },
    { label: "Smallest", value: "smallest" },
    { label: "Biggest", value: "biggest" },
  ]
  const [sorting, setSorting] = useState(sortingOptions[0])



  const updateFilters = (page, newFilters, force) => {
    if (newFilters && !force) {
      /*
        If new filters are being set, 
        they are managed via a search form so 
        just overwrite whatever is stored in the 
        context with the new filters
      */
      //fetchFromStorage(STORAGE_KEY)
      if (!R.equals(filters, newFilters)) {
        setIsSearching(true)
        setFilters(newFilters)
      }
    } else {
      /* 
        If no new filters are defined, then reset the filters 
        in the context to the page details
      */
      newFilters = {
        ...newFilters,
        types: [page.type],
        locations: [
          ...(page.city || (page.city && page.area)
            ? [
                {
                  label:
                    page.city && page.area
                      ? `${toTitleCase(page.area)}, ${toTitleCase(page.city)}`
                      : `${toTitleCase(page.city)}`,
                  ...(page.city &&
                    page.area && { city: page.city, area: page.area }),
                  ...(page.city && { city: page.city }),
                },
              ]
            : []),
        ],
      }
      if (!R.equals(filters, newFilters)) {
        setIsSearching(true)
        setFilters(newFilters)
      }
    }
  }

  const loadIndexes = () => {

    async function fetchSSI() {
      let response = await fetch('/siteSearchIndex.json')
      response = await response.json()
      ssi = Index.load(response)
      setSearchIndex(ssi);
      loadSearchIndex();
      setIndexes1Loaded(true)
    }
    !index1Loaded && fetchSSI()
    async function fetchPremises() {
      let response = await fetch('/searchPremises.json')
      response = await response.json()
      ssiPremises = R.pathOr([], ["nodes"], response)
      setAllPremises(ssiPremises);
      setIndexes2Loaded(true)
    }
    !index2Loaded && fetchPremises()
    async function fetchAutocomplete() {
      let response = await fetch('/searchPremisesAutoComplete.json')
      response = await response.json()
      ssiAutocomplete = R.pathOr([], ["nodes"], response)
      setAutocompleteFilters(ssiAutocomplete);
      setIndexes3Loaded(true)
    }
    !index3Loaded && fetchAutocomplete()

  }

  const loadSearchIndex = async () => {
    if (isClient && !elasticLunrSearch && siteSearchIndex) {
      setElasticLunrSearch(siteSearchIndex);
    }
  }
  
  const resetSearch = () => {
    setResultPage(null)
    setFilters({})
  }

  const execSearch = (allPremises, filters) => {

    //console.log('Search filters',filters);

    // Perf improvement:
    // If filters are empty, just return all premises without evaluating anything (make sure promoted appear first)
    if (filters && R.keys(filters).length === 0) {
      return allPremises.sort((a, b) => {
        return (a.promoted === "true") & (b.promoted === "true")
          ? 0
          : a.promoted === "true"
          ? -1
          : b.promoted === "true"
          ? 1
          : 0
      })
    }

    /* 
          Two lists are created to make sure promoted premises appear fist
          They are merged before returning, with the promoted items appearing first

          This is handled as two arrays rather than sorting to avoid looping the results twice
        */
    const filteredPromotedPremises = []
    const filteredBasicPremises = []

    /*
          There are certain usecases where we need to filter the given location filters before running the search

          One example are when two filters are supplied: 
          {city: "Helsinki"}
          {city: "Helsinki", area: "Kalasatama"}

          In this case the search should only show filters for 
          {city: "Helsinki", area: "Kalasatama"}
          (and not all Helsinki)

          For this case, the serach will make a filter object so that it understands 
          if a city has more specific criteria.

          This behaviour is not programatically the most logical, but was decided 
          it is the most logical for users of the site.

        */

    const locationFilters = {}
    R.pathOr([], ["locations"], filters).forEach(loc => {
      if (loc.city) {
        if (!locationFilters[loc.city]) {
          locationFilters[loc.city] = []
        }
        if (loc.area || loc.postcode || loc.street) {
          locationFilters[loc.city].push(loc)
        }
      }
    })

    /* Next, if free text search is defined then just execute 
        that first to get a short list of document IDs that can be filtered 
        first before running further evaluations */
    let validIds = []
    if (filters.freeTextFilter && filters.freeTextFilter !== "") {
      validIds = freeTextSearch(filters.freeTextFilter)
    }

    allPremises.forEach(premises => {
      try {
        /* 
              FREE TEXT FILTER 
              This is probably very specific and easy to filter results, 
              so evaluate against this first to reduce the number of results
            */
        if (filters.freeTextFilter && filters.freeTextFilter !== "") {
          if (validIds.indexOf(R.path(["id"], premises)) < 0) {
            // Did not satisfy the free text search, discard
            return
          }
        }

        /*
              LOCATIONS FILTERING
            */
        if (R.pathOr([], ["locations"], filters).length !== 0) {
          let locationMatch = false
          R.forEachObjIndexed((filterData, filterCity) => {
            // If there already is one match, just skip evaluating further
            if (locationMatch) return

            if (premises.areaToUse && 
              premises.city &&
              premises.streetAddress && 
              filterCity &&
              (`${filterCity}`.toLowerCase() === 'helsinki') && 
              (premises.streetAddress.toLowerCase() === "lapinlahdenkatu 3") &&
              (premises.areaToUse.toLowerCase() === "kamppi, keskusta") && 
              (premises.city.toLowerCase() === "helsinki")) {
              locationMatch = true
              return
            }

            // If there are no specific filters for the city, just check if there is a city match
            if (
              filterData.length === 0 &&
              `${premises.city}`.toLowerCase() === `${filterCity}`.toLowerCase()
            ) {
              locationMatch = true
              return
            }
            // If there are additional filters, make sure the premises matches at least one filter
            filterData.map(loc => {
              if (locationMatch) return
              if (
                loc.city &&
                `${premises.city}`.toLowerCase() !== `${loc.city}`.toLowerCase()
              )
                return
              if (
                loc.street &&
                `${premises.streetAddress}`
                  .toLowerCase()
                  .indexOf(`${loc.street}`.toLowerCase()) < 0
              )
                return
              if (loc.postcode && `${premises.postcode}` !== `${loc.postcode}`)
                return
              if (
                loc.area &&
                R.pathOr([], ["areas"], premises)
                  .map(a => a.toLowerCase())
                  .indexOf(loc.area) < 0
              )
                return

              // If it has got this far then the premises has matched at least one filter
              locationMatch = true
            })
          }, locationFilters)
          // If the space didn't pass the test, return
          if (!locationMatch) return
        }

        /*
              TYPES FILTERING
            */
        // Only run this test if 'toimitilat' is not defined as a type (or if no types are defined)
        if (
          R.pathOr([], ["types"], filters).indexOf("toimitilat") < 0 &&
          R.pathOr([], ["types"], filters).length > 0
        ) {
          // If others types are specified, check the premises against each of the types.

          let typeMatch = false
          R.pathOr([], ["types"], filters).forEach(type => {

            const stypes = searchTypes[type] // Types to use for searching against premises.

            // If the premises contains one of the serach type strings, it can be included in the results.
            // If it doesn't, then the premises is rejected
            stypes.forEach(stype => {

              if (
                R.pathOr([], ["types"], premises)
                  .map(ptype => ptype.toLowerCase())
                  .indexOf(stype) > -1
              ){
                typeMatch = true
              }
            })
          })
          if (!typeMatch) return // Return if the premises didn't pass the test
        }

        /*
              SIZE FILTERING
            */
        if (R.path(["sizeMin"], filters)) {
          const min = parseFloat(R.path(["sizeMin"], filters))
          if (min && min > R.pathOr(0, ["size", "advertiseSqMeters"], premises))
            return // Premises too small
        }
        if (R.path(["sizeMax"], filters)) {
          const max = parseFloat(R.path(["sizeMax"], filters))
          if (max && max < R.pathOr(0, ["size", "advertiseSqMeters"], premises))
            return // Premises too big
        }

        /*
              PREMISES TYPE SPECIFIC FILTERING
            */

        if (R.pathOr([], ["types"], filters).indexOf("toimistot") > 0) {
          if (
            R.pathOr(
              R.path(["sizeMin"], filters),
              ["toimistotSizeMin"],
              filters
            )
          ) {
            const min = parseFloat(R.path(["toimistotSizeMin"], filters))
            if (
              min &&
              min > R.pathOr(0, ["size", "minSqMetersOffice"], premises)
            )
              return // Premises too small
          }
          if (
            R.pathOr(
              R.path(["sizeMax"], filters),
              ["toimistotSizeMax"],
              filters
            )
          ) {
            const max = parseFloat(R.path(["toimistotSizeMax"], filters))
            if (
              max &&
              max < R.pathOr(0, ["size", "maxSqMetersOffice"], premises)
            )
              return // Premises too big
          }
        }

        if (R.pathOr([], ["types"], filters).indexOf("liiketilat") > 0) {
          if (
            R.pathOr(
              R.path(["sizeMin"], filters),
              ["liiketilatSizeMin"],
              filters
            )
          ) {
            /*
            const min = parseFloat(R.path(["liiketilatSizeMin"], filters))
            if (
              min &&
              min > R.pathOr(0, ["size", "minSqMetersRetail"], premises)
            )
              return // Premises too small
            */
          }
          if (
            R.pathOr(
              R.path(["sizeMax"], filters),
              ["liiketilatSizeMax"],
              filters
            )
          ) {
            const max = parseFloat(R.path(["liiketilatSizeMax"], filters))
            if (
              max &&
              max < R.pathOr(0, ["size", "maxSqMetersRetail"], premises)
            )
              return // Premises too big
          }
        }

        if (R.pathOr([], ["types"], filters).indexOf("varastot") > 0) {
          if (
            R.pathOr(R.path(["sizeMin"], filters), ["varastotSizeMin"], filters)
          ) {
            const min = parseFloat(R.path(["varastotSizeMin"], filters))
            if (
              min &&
              min > R.pathOr(0, ["size", "minSqMetersLogistics"], premises)
            )
              return // Premises too small
          }
          if (
            R.pathOr(R.path(["sizeMax"], filters), ["varastotSizeMax"], filters)
          ) {
            const max = parseFloat(R.path(["varastotSizeMax"], filters))
            if (
              max &&
              max < R.pathOr(0, ["size", "maxSqMetersLogistics"], premises)
            )
              return // Premises too big
          }
        }

        if (R.pathOr([], ["types"], filters).indexOf("tuotantotilat") > 0) {
          if (
            R.pathOr(
              R.path(["sizeMin"], filters),
              ["tuotantotilatSizeMin"],
              filters
            )
          ) {
            const min = parseFloat(R.path(["tuotantotilatSizeMin"], filters))
            if (
              min &&
              min >
                R.pathOr(0, ["size", "minSqMetersProductionSpace"], premises)
            )
              return // Premises too small
          }
          if (
            R.pathOr(
              R.path(["sizeMax"], filters),
              ["tuotantotilatSizeMax"],
              filters
            )
          ) {
            const max = parseFloat(R.path(["tuotantotilatSizeMax"], filters))
            if (
              max &&
              max <
                R.pathOr(0, ["size", "maxSqMetersProductionSpace"], premises)
            )
              return // Premises too big
          }
        }

        /*
              WORKSPACES FILTERING
               Only run this when the premises being evaluated is of type "toimistot" or null
            */
        if (
          R.pathOr([], ["types"], premises).length === 0 ||
          R.pathOr([], ["types"], premises).indexOf("Toimistotila") > -1
        ) {
          if (R.path(["workspaces", "min"], filters)) {
            const min = parseInt(R.path(["workspaces", "min"], filters))
            // Evaluate against the 3 different office types, if none match then the premises is too small
            if (
              min &&
              min >
                R.pathOr(0, ["workstations", "activity", "min"], premises) &&
              min > R.pathOr(0, ["workstations", "open", "min"], premises) &&
              min > R.pathOr(0, ["workstations", "rooms", "min"], premises)
            )
              return // Premises too small
          }
          if (R.path(["workspaces", "max"], filters)) {
            const max = parseInt(R.path(["workspaces", "max"], filters))
            // Evaluate against the 3 different office types, if none match then the premises is too large
            if (
              max &&
              max <
                R.pathOr(0, ["workstations", "activity", "max"], premises) &&
              max < R.pathOr(0, ["workstations", "open", "max"], premises) &&
              max < R.pathOr(0, ["workstations", "rooms", "max"], premises)
            )
              return // Premises too large
          }
        }

        /* Tag filtering - if a tag has been checked, filters spaces that contain that tag */

        /* Office Tags */
        if (R.path(["toimistot-Aulapalvelu"], filters)) {
          if (
            R.pathOr([], ["building", "services"], premises).indexOf(
              "Aulapalvelu"
            ) < 0
          ) {
            return
          }
        }
        if (R.path(["toimistot-Lounasravintola"], filters)) {
          if (
            R.pathOr([], ["building", "services"], premises).indexOf(
              "Lounasravintola"
            ) < 0
          ) {
            return
          }
        }
        if (R.path(["toimistot-Vuokrattavat pysäköintipaikat"], filters)) {
          if (
            R.pathOr([], ["building", "services"], premises).indexOf(
              "Vuokrattavat pysäköintipaikat"
            ) < 0
          ) {
            return
          }
        }
        if (R.path(["toimistot-Vuokrattavia neuvottelutiloja"], filters)) {
          if (
            R.pathOr([], ["building", "services"], premises).indexOf(
              "Vuokrattavia neuvottelutiloja"
            ) < 0
          ) {
            return
          }
        }
        if (R.path(["toimistot-Sähköauton latauspaikat"], filters)) {
          if (
            R.pathOr([], ["building", "services"], premises).indexOf(
              "Sähköauton latauspaikka"
            ) < 0
          ) {
            return
          }
        }

        /* Retail Tags */
        if (R.path(["liiketilat-Katutason liiketila"], filters)) {
          if (
            R.pathOr([], ["building", "retail"], premises).indexOf(
              "Katutason liiketila"
            ) < 0
          ) {
            return
          }
        }
        if (R.path(["liiketilat-Kauppakeskuksessa oleva tila"], filters)) {
          if (
            R.pathOr([], ["building", "retail"], premises).indexOf(
              "Kauppakeskuksessa oleva tila"
            ) < 0
          ) {
            return
          }
        }
        if (R.path(["liiketilat-Ravintolatilat"], filters)) {
          if (
            R.pathOr([], ["building", "retail"], premises).indexOf(
              "Ravintolatilat"
            ) < 0
          ) {
            return
          }
        }
        if (R.path(["liiketilat-Terveys- ja hyvinvointitilat"], filters)) {
          if (
            R.pathOr([], ["building", "retail"], premises).indexOf(
              "Terveys- ja hyvinvointitilat"
            ) < 0
          ) {
            return
          }
        }

        /* Logistics Tags */
        if (R.path(["varastot-Lastauslaituri"], filters)) {
          if (
            R.pathOr([], ["building", "logistics"], premises).indexOf(
              "Lastauslaituri"
            ) < 0
          ) {
            return
          }
        }
        if (R.path(["varastot-Lattian kantavuus"], filters)) {
          if (
            R.pathOr([], ["building", "logistics"], premises).indexOf(
              "Lattian kantavuus"
            ) < 0
          ) {
            return
          }
        }
        if (R.path(["varastot-Nosto-ovet"], filters)) {
          if (
            R.pathOr([], ["building", "logistics"], premises).indexOf(
              "Nosto-ovet"
            ) < 0
          ) {
            return
          }
        }
        if (R.path(["varastot-Tilan korkeus yli 4 m"], filters)) {
          if (
            R.pathOr([], ["building", "logistics"], premises).indexOf(
              "Tilan korkeus yli 4 m"
            ) < 0
          ) {
            return
          }
        }

        /* Production Tags */
        if (R.path(["tuotantotilat-Lastauslaituri"], filters)) {
          if (
            R.pathOr([], ["building", "productionLogistics"], premises).indexOf(
              "Lastauslaituri"
            ) < 0
          ) {
            return
          }
        }
        if (R.path(["tuotantotilat-Nosto-ovet"], filters)) {
          if (
            R.pathOr([], ["building", "productionLogistics"], premises).indexOf(
              "Nosto-ovet"
            ) < 0
          ) {
            return
          }
        }
        if (R.path(["tuotantotilat-Tilan korkeus yli 4 m"], filters)) {
          if (
            R.pathOr([], ["building", "productionLogistics"], premises).indexOf(
              "Tilan korkeus yli 4 m"
            ) < 0
          ) {
            return
          }
        }

        /* 
              Sale or Rental tags 
              If none selected, include everything & if both selected, include everything
              Only filter results if the two tags are different.
              The reason the data has been enetered this way is from the design
            */
        if (R.path(["sale"], filters) && !R.path(["rent"], filters)) {
          // If premises is not marked as on sale, exclude it
          if (R.path(["onSale"], premises) !== "true") return
        }
        if (!R.path(["sale"], filters) && R.path(["rent"], filters)) {
          // If premises is marked as on sale, exclude it (keep everything else)
          if (R.path(["onSale"], premises) === "true") return
        }

        /* 
              Evironmental Certificates
            */
        if (R.path(["certificates"], filters)) {
          // If premises is not marked as having an energy certificate, exlcude it
          if (R.pathOr([], ["extraInfo", "certificates"], premises).length < 1)
            return
        }

        // Finally, if the premises has made it all the way through the filtering, then it is included in the results
        if (R.path("promoted", premises) === "true") {
          filteredPromotedPremises.push(premises)
        } else {
          filteredBasicPremises.push(premises)
        }
      } catch (error) {
        console.error("TMP LOGGING: search error", error)
        // If any issues filtering, just skip the premises
      }
    })

    return filteredPromotedPremises.concat(filteredBasicPremises)
  }

  const freeTextSearch = query => {
    try {
      if (!!elasticLunrSearch) {
        const res = elasticLunrSearch.search(query, { expand: true })
        return res.map(r => r.ref)
      }
    } catch (error) {
      console.error("TMP LOGGING: search error", error)
    }
  }

  const sortFilteredPremises = sort => {
    filteredPremises.sort((a, b) => {
      try {
        switch (sort) {
          case "recommended":
            //console.log('Sorting by recommended');
            return 0
          case "newest":
            //console.log('Sorting by newest');
            return
          case "oldest":
            //console.log('Sorting by oldest');
            return
          case "smallest":
            //console.log('Sorting by smallest');
            return
          case "biggest":
            //console.log('Sorting by biggest');
            return
          default:
            //console.log("no sorting");
            return 0
        }
      } catch (e) {
        return 0
      }
    })
  }

  /*
    This search tool is just for the chatbot and runs a synchronous search 
    that directly returns a copy of filtered premises. It is probably a hack, 
    but it makes sure that the results are ready before sending any information 
    in the chatbot chat window.
  */
  const execSyncSearch = filters => {
    return execSearch(allPremises, filters)
  }

  /*

  */
  useEffect(() => {
    if(!indexesLoaded){return;}
    putToSessionStorage(STORAGE_KEY, filters, true)
    setIsSearching(true)
    setFilteredPremises(execSearch(allPremises, filters))
    setIsSearching(false)
    setResultPage(null)
  }, [filters, indexesLoaded])

  useEffect(() => {
    sortFilteredPremises(sorting)
  }, [sorting])

  useEffect(() => {
    if (!resultPage) setResultPage(1)
  }, [resultPage])

  return (
    <SearchContext.Provider
      value={{
        allPremises,
        siteSearchIndex,
        filteredPremises,
        filters,
        updateFilters: (page, newFilters, force) =>
          updateFilters(page, newFilters, force),
        isSearching,
        autocompleteFilters,
        loadSearchIndex: () => loadSearchIndex(),
        loadIndexes: () => loadIndexes(),
        resultPage,
        setResultPage: page => setResultPage(page),
        sortingOptions,
        sorting,
        setSorting: sorting => setSorting(sorting),
        setSearchUrl: url => setSearchUrl(url),
        execSyncSearch: filters => execSyncSearch(filters),
        resetSearch: () => resetSearch(),
        heroLoaded,
        setHeroLoaded: bool => setHeroLoaded(bool),
        preloadIndex: preload => preloadIndex(preload),
        indexesLoaded
      }}
    >
      {props.children}
    </SearchContext.Provider>
  )
}

export default SearchContext

export { SearchProvider }
