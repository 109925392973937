/*
    These utils are a very thin wrapper around storage to just 
    make sure it only runs in browsers and errors are handled 
    (e.g. localstorage doesn't exist).

    These are meant to make a slightly more persistant cache 
    for the search tools, incase the window is reloaded.

    Localstorage is used to share information between browser tabs
*/

const isClient = typeof window !== "undefined"

export const putToStorage = (key, data, isJson) => {
  try {
    data = isJson ? JSON.stringify(data) : data
    if (isClient) localStorage.setItem(key, data)
    return true
  } catch (e) {
    return
  }
}

export const fetchFromStorage = (key, isJson) => {
  try {
    const data = isClient ? localStorage.getItem(key) : null
    return data && isJson ? JSON.parse(data) : data
  } catch (e) {
    return
  }
}

export const flushStorage = key => {
  try {
    if (isClient) localStorage.removeItem(key)
    return true
  } catch (e) {
    return
  }
}

export const putToSessionStorage = (key, data, isJson) => {
  try {
    data = isJson ? JSON.stringify(data) : data
    if (isClient) sessionStorage.setItem(key, data)
    return true
  } catch (e) {
    return
  }
}

export const fetchFromSessionStorage = (key, isJson) => {
  try {
    const data = isClient ? sessionStorage.getItem(key) : null
    return data && isJson ? JSON.parse(data) : data
  } catch (e) {
    return
  }
}

export const flushSessionStorage = key => {
  try {
    if (isClient) sessionStorage.removeItem(key)
    return true
  } catch (e) {
    return
  }
}
