import React, { useState } from "react"
import PropTypes from "prop-types"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"

//import styles from './footer.module.scss'
import { makeStyles } from "@material-ui/core/styles"
import colors from "../../theme/colors"

/*
TODO: These astyles are a mess, then need cleaned up
*/
const useStyles = makeStyles(theme => ({
  root: {
    padding: "0.5rem 1rem",
  },
  clickable: {
    cursor: "pointer",
  },
  alignLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  alignRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  inputBox: {
    "& .MuiInputBase-root": {
      /* Hacking in style override for the moment */
      border: `1px solid ${colors.blue[1]}`,
      borderRadius: "6px 0 6px 6px !important",
      padding: "0",
      "& .MuiInputBase-input": {
        padding: "0.25em 1em !important",
      },
      "& svg": {
        height: "0.6em",
        width: "0.6em",
      },
    },
  },
  messageContent: {
    padding: "0.25em 1em",
  },
  outgoingMessage: {
    color: colors.base.white,
    backgroundColor: colors.blue[1],
    border: `1px solid ${colors.blue[1]}`,
    borderRadius: "6px 0 6px 6px",
    "&$clickable": {
      "&:hover": {
        borderColor: colors.blue[0],
        background: colors.blue[0],
      },
    },
  },
  disabled: {
    borderColor: colors.grey[1],
    background: colors.grey[1],
    "&:hover": {
      borderColor: colors.grey[1],
      background: colors.grey[1],
    },
  },
}))

const ChatTextInput = ({ onClick, placeholder }) => {
  const [value, setValue] = useState("")
  const [clicked, setClicked] = useState(false)
  const clickable = !!onClick

  const handleInput = e => {
    setValue(e.target.value)
  }
  const handleSubmit = () => {
    if (value && !clicked) {
      setClicked(true)
      if (!!onClick) {
        onClick(value)
      }
    }
  }

  const classes = useStyles()

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={1}
      className={classes.root}
    >
      <Grid item style={{ flexGrow: 1 }}>
        {placeholder === 'Sähköposti, sijainti, tilatyyppi, tilan koko' && <Typography variant="body2" component="span">
          Jätä meille yhteystietosi sekä tilatarpeesi (sijainti, tilatyyppi, tilan koko/ henkilömäärä) niin otamme sinuun yhteyttä
        </Typography>}
        <TextField
          className={classes.inputBox}
          onChange={handleInput}
          placeholder={placeholder}
          multiline
          rows={2}
          rowsMax={2}
          variant="outlined"
        />
      </Grid>
      <Grid item>
        <Box onClick={handleSubmit}>
          <Box
            className={`${classes.messageBox} ${classes.outgoingMessage} ${
              clickable && !clicked ? classes.clickable : ""
            } ${value === "" ? classes.disabled : ""}`}
          >
            <Box className={classes.messageContent}>OK</Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

ChatTextInput.propTypes = {
  direction: PropTypes.oneOf(["outgoing", "incoming"]).isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default ChatTextInput
