// https://www.newsec.com/globalassets/images/design-manual/newsec-digital-design-manual.pdf
// https://nordicmorning.invisionapp.com/d/main?origin=v7#/console/19371598/404620474/preview?scrollOffset=0
export default {
  base: {
    black: "#000000",
    white: "#FFFFFF",
    creme: "#F6F5ED",
    okGreen: "#6A840A",
    errorRed: "#C60018",
  },
  grey: {
    "-": "#DFE5E8",
    "0": "#D1D7DA",
    "1": "#90999E",
    "2": "#5D6A70",
    "3": "#33434B",
  },
  blue: {
    "-": "#EBF1F4",
    "0": "#B4CCE3",
    "1": "#377EB8",
    "2": "#214C6F",
    "3": "#1F3B54",
    "4": "#0a1925",
  },
}
