import React from "react"

import Typography from "@material-ui/core/Typography"

import ChatMessage from "../ChatMessage"
import ChatAutocompleteInput from "../inputs/ChatAutocompleteInput"

import toTitleCase from "../../../utils/toTitleCase"

export const WhatCity = () => {
  return [
    <ChatMessage direction="incoming">
      <Typography variant="body2" component="span">
        Miltä paikkakunnalta etsit tilaa?
      </Typography>
    </ChatMessage>,
  ]
}

export const WhatCityInput = (onClick, options) => {
  return [
    <ChatAutocompleteInput
      direction="outgoing"
      onClick={city =>
        onClick({ filters: { city: city.toLowerCase() } }, toTitleCase(city))
      }
      onCancel={() => onClick({ collectContact: true }, "En löydä etsimääni.")}
      placeholder="Kaupunki"
      options={options}
    ></ChatAutocompleteInput>,
  ]
}

export const IncludeArea = () => {
  return [
    <ChatMessage direction="incoming">
      <Typography variant="body2" component="span">
        Onko kaupunginosalla sinulle merkitystä?
      </Typography>
    </ChatMessage>,
  ]
}

export const IncludeAreaInput = onClick => {
  return [
    <ChatMessage
      direction="outgoing"
      onClick={() => onClick({ includeArea: true }, "Kyllä")}
    >
      <Typography variant="body2" component="span">
        Kyllä
      </Typography>
    </ChatMessage>,
    <ChatMessage
      direction="outgoing"
      onClick={() => onClick({ includeArea: false }, "Ei")}
    >
      <Typography variant="body2" component="span">
        Ei
      </Typography>
    </ChatMessage>,
  ]
}

export const WhatArea = () => {
  return [
    <ChatMessage direction="incoming">
      <Typography variant="body2" component="span">
        Miltä kaupunginosalta etsit tilaa?
      </Typography>
    </ChatMessage>,
  ]
}

export const WhatAreaInput = (onClick, options) => {
  return [
    <ChatAutocompleteInput
      direction="outgoing"
      onClick={area =>
        onClick({ filters: { area: area.toLowerCase() } }, toTitleCase(area))
      }
      onCancel={() => onClick({ collectContact: true }, "En löydä etsimääni.")}
      placeholder="Kaupunginosa"
      options={options}
    ></ChatAutocompleteInput>,
  ]
}
