import React from "react"

import Typography from "@material-ui/core/Typography"

import ChatMessage from "../ChatMessage"
import ChatRangeInput from "../inputs/ChatRangeInput"
import ChatDropdownInput from "../inputs/ChatDropdownInput"

export const WhatSize = () => {
  return [
    <ChatMessage direction="incoming">
      <Typography variant="body2" component="span">
        Minkä kokoista tilaa etsit?
      </Typography>
    </ChatMessage>,
  ]
}

export const WhatSqmSizeInput = onClick => {
  return [
    <ChatRangeInput
      direction="outgoing"
      onClick={(sizeFilters, rangeString) =>
        onClick({ filters: { ...sizeFilters } }, rangeString)
      }
    ></ChatRangeInput>,
  ]
}

export const WhatWorkspaceSizeInput = onClick => {
  return [
    <ChatDropdownInput
      direction="outgoing"
      onClick={(sizeFilters, rangeString) =>
        onClick({ filters: { ...sizeFilters } }, rangeString)
      }
    ></ChatDropdownInput>,
  ]
}
