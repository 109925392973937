import React from "react"
import { ChatbotProvider } from "./src/contexts/ChatbotContext"
import { SearchProvider } from "./src/contexts/SearchContext"
import { loadableReady } from '@loadable/component'
import { hydrate } from 'react-dom'

import carousel from "./static/carousel.css" // Not sure why this was added here (originally as a "required(..)")

export const wrapRootElement = ({ element }) => (
  <SearchProvider>
    <ChatbotProvider>{element}</ChatbotProvider>
  </SearchProvider>
)

export const replaceHydrateFunction = () => (element, container, callback) => {
  loadableReady(() => {
    hydrate(element, container, callback)
  })
}