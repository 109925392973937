import React, { useState } from "react"
import PropTypes from "prop-types"
import Autocomplete from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"

import toTitleCase from "../../../utils/toTitleCase"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"

//import styles from './footer.module.scss'
import { makeStyles } from "@material-ui/core/styles"
import colors from "../../theme/colors"

/*
TODO: These astyles are a mess, then need cleaned up
*/
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: "1",
    padding: "0.5rem 1rem",
  },
  clickable: {
    cursor: "pointer",
  },
  alignLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  alignRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  autocompleteBox: {
    "& .MuiInputBase-root": {
      /* Hacking in style override for the moment */
      border: `1px solid ${colors.blue[1]}`,
      borderRadius: "6px 0 6px 6px !important",
      padding: "0",
      "& .MuiInputBase-input": {
        padding: "0.25em 1em !important",
      },
      "& svg": {
        height: "0.6em",
        width: "0.6em",
      },
      fontSize: "0.7rem",
      "@media (min-height: 500px)": {
        fontSize: "0.9rem",
      },
    },
  },
  messageContent: {
    padding: "0.15em 0.75em",
    fontSize: "0.7rem",
    "& span": {
      fontSize: "0.7rem",
    },
    "@media (min-height: 500px)": {
      padding: "0.25em 1em",
      fontSize: "0.9rem",
      "& span": {
        fontSize: "0.9rem",
      },
    },
  },
  outgoingMessage: {
    color: colors.base.white,
    backgroundColor: colors.blue[1],
    border: `1px solid ${colors.blue[1]}`,
    borderRadius: "6px 0 6px 6px",
    "&$clickable": {
      "&:hover": {
        borderColor: colors.blue[0],
        backgroundColor: colors.blue[0],
      },
    },
  },
  secondary: {
    borderColor: colors.blue[0],
    background: colors.blue[0],
    "&$clickable": {
      "&:hover": {
        borderColor: colors.blue[1],
        backgroundColor: colors.blue[1],
      },
    },
  },
  disabled: {
    borderColor: colors.grey[1],
    backgroundColor: colors.grey[1],
    "&$clickable": {
      "&:hover": {
        borderColor: colors.grey[1],
        backgroundColor: colors.grey[1],
      },
    },
  },
}))

const ChatAutocompleteInput = ({ onClick, onCancel, placeholder, options }) => {
  const [value, setValue] = useState("")
  const [clicked, setClicked] = useState(false)
  const clickable = !!onClick

  const setSelected = (e, v) => {
    setValue(v)
  }
  const handleSubmit = () => {
    if (value && !clicked) {
      setClicked(true)
      if (!!onClick) {
        onClick(value)
      }
    }
  }

  const handleCancel = () => {
    if (!clicked) {
      setClicked(true)
      if (!!onCancel) {
        onCancel()
      }
    }
  }

  const classes = useStyles()

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={1}
      className={classes.root}
    >
      <Grid item style={{ flexGrow: 1 }}>
        <Autocomplete
          options={options}
          noOptionsText="Ei hakutuloksia"
          getOptionLabel={option => toTitleCase(option)}
          openOnFocus={true}
          className={classes.autocompleteBox}
          onChange={setSelected}
          popupIcon={null}
          renderInput={params => (
            <TextField
              {...params}
              placeholder={placeholder}
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item>
        <Box onClick={handleSubmit}>
          <Box
            className={`${classes.messageBox} ${classes.outgoingMessage} ${
              clickable && !clicked ? classes.clickable : ""
            } ${value === "" ? classes.disabled : ""}`}
          >
            <Box className={classes.messageContent}>OK</Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box onClick={handleCancel}>
          <Box
            className={`${classes.messageBox} ${classes.outgoingMessage} ${
              clickable && !clicked ? classes.clickable : ""
            } ${classes.secondary}`}
          >
            <Box className={classes.messageContent}>En löydä etsimääni.</Box>
          </Box>
        </Box>
      </Grid>

      {/**/}
    </Grid>
  )
}

ChatAutocompleteInput.propTypes = {
  direction: PropTypes.oneOf(["outgoing", "incoming"]).isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default ChatAutocompleteInput
