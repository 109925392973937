import React from "react"
import Box from "@material-ui/core/Box"
import { makeStyles } from "@material-ui/core/styles"
import colors from "../theme/colors"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "0.25rem",
    marginBottom: "0.25rem",
    display: "flex",
    alignItems: "center",

    "& span": {
      height: "15px",
      width: "15px",
      float: "left",
      margin: "0 1px",
      backgroundColor: colors.blue[0],
      display: "block",
      borderRadius: "50%",
      opacity: "0.4",
    },
    "& span:nth-child(1)": {
      animation: "1s $blink infinite .3333s",
    },
    "& span:nth-child(2)": {
      animation: "1s $blink infinite .6666s",
    },
    "& span:nth-child(3)": {
      animation: "1s $blink infinite .9999s",
    },
  },
  "@keyframes blink": {
    "50%": {
      opacity: "1",
    },
  },
}))

const TypingIndicator = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <span></span>&nbsp;
      <span></span>&nbsp;
      <span></span>
    </Box>
  )
}

export default TypingIndicator
