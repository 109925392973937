import React from "react"
import * as R from "ramda"
import Typography from "@material-ui/core/Typography"

import { Link } from "gatsby"

import ChatMessage from "../ChatMessage"

export const Results = () => {
  return [
    <ChatMessage direction="incoming">
      <Typography variant="body2" component="span">
        Pieni hetki, niin etsin sinulle kohteita...
      </Typography>
    </ChatMessage>,
  ]
}

export const FoundResults = (number, link) => {

  function pushDataLayer(){

    const dataLayerObject = {
      'event':'chatbot',
      'eventAction':'clickToSearchResults',
    };

    // Push datalayer object
    if (typeof window !== 'undefined') {
      if(window.dataLayer){
        if(window.dataLayer.length > 0 && !R.equals(window.dataLayer[window.dataLayer.length - 1], dataLayerObject)){
          // eslint-disable-next-line no-undef
          window.dataLayer.push(dataLayerObject);
        }else if(window.dataLayer.length === 0){
          // eslint-disable-next-line no-undef
          window.dataLayer.push(dataLayerObject);
        }
      }else{
        window.dataLayer = [];
        // eslint-disable-next-line no-undef
        window.dataLayer.push(dataLayerObject);
      }
    }
  }

  const message =
    number > 1
      ? `Löysin sinulle ${number} hakutulosta`
      : `Löysin sinulle ${number} hakutuloksen`
  return [
    <ChatMessage direction="incoming">
      <Typography variant="body2" component="span">
        {message}
      </Typography>
      <br />
      <br />
      <Typography variant="body2" component="span">
        <Link title={message} to={`/${link}`} onClick={pushDataLayer}>
          Katso ne täältä &#187;
        </Link>
      </Typography>
    </ChatMessage>,
  ]
}

export const NoResults = () => {
  return [
    <ChatMessage direction="incoming">
      <Typography variant="body2" component="span">
        Harmi! En löytänyt sopivia kohteita juuri nyt.
      </Typography>
    </ChatMessage>,
  ]
}

export const WhatSizeInput = onClick => {
  return ["Toimisto", "Liiketila", "Varasto", "Tuotantotila"].map(type => {
    return (
      <ChatMessage
        direction="outgoing"
        onClick={() => onClick({ filters: { type: type.toLowerCase() } }, type)}
      >
        <Typography variant="body2" component="span">
          {type}
        </Typography>
      </ChatMessage>
    )
  })
}
