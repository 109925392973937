import React from "react"

import Typography from "@material-ui/core/Typography"

import ChatMessage from "../ChatMessage"

export const Intro = () => {
  return [
    <ChatMessage direction="incoming">
      <Typography variant="body2" component="span">
        Hei!
      </Typography>
    </ChatMessage>,
    <ChatMessage direction="incoming">
      <Typography variant="body2" component="span">
        Olen Toivo Toimitilabotti. Voinko auttaa sinua löytämään sopivan tilan?
      </Typography>
    </ChatMessage>,
  ]
}

export const IntroInputs = onClick => {
  return [
    <ChatMessage
      direction="outgoing"
      onClick={() => onClick({ started: true }, "Kyllä")}
    >
      <Typography variant="body2" component="span">
        Kyllä
      </Typography>
    </ChatMessage>,
    <ChatMessage
      direction="outgoing"
      onClick={() => onClick({ started: true, collectContact: true }, "Ei")}
    >
      <Typography variant="body2" component="span">
        Ei
      </Typography>
    </ChatMessage>,
  ]
}
