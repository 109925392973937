import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"

import Box from "@material-ui/core/Box"
import TypingIndicator from "./TypingIndicator"

//import styles from './footer.module.scss'
import { makeStyles } from "@material-ui/core/styles"
import colors from "../theme/colors"

const useStyles = makeStyles(theme => ({
  clickable: {
    cursor: "pointer",
  },
  alignLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  alignRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  messageBox: {
    maxWidth: "80%",
    width: "fit-content",
    margin: "0.2em 1em",
    "@media (min-height: 500px)": {
      margin: "0.5em 1em",
    },
  },
  messageContent: {
    padding: "0.15em 0.75em",
    "& span": {
      fontSize: "0.7rem",
    },
    "@media (min-height: 500px)": {
      padding: "0.25em 1em",
      "& span": {
        fontSize: "0.9rem",
      },
    },

    "& a": {
      fontWeight: "bold",
      color: colors.base.black,
      "&:hover": {
        color: colors.blue[1],
      },
    },
  },
  outgoingMessage: {
    color: colors.base.white,
    backgroundColor: colors.blue[1],
    border: `1px solid ${colors.blue[1]}`,
    borderRadius: "6px 0 6px 6px",
    "&$clickable": {
      "&:hover": {
        borderColor: colors.blue[0],
        background: colors.blue[0],
      },
    },
  },
  incomingMessage: {
    backgroundColor: colors.base.white,
    border: `1px solid ${colors.grey[0]}`,
    borderRadius: "0 6px 6px 6px",
  },
}))

const ChatMessage = ({ direction, onClick, typingDelay, children }) => {
  const [hasDelayed, setHasDelayed] = useState(false)
  const [isMessagePending, setIsMessagePending] = useState(
    typingDelay ? true : false
  )

  const [clicked, setClicked] = useState(false)
  const clickable = !!onClick

  const handleClick = () => {
    if (!clicked) {
      setClicked(true)
      if (!!onClick) {
        onClick()
      }
    }
  }

  /*
    Typing delay is for messages sent by the chatbot as displays 
    three dots to make it look like the chatbot is typing a message.
  */
  useEffect(() => {
    const timer = setTimeout(() => {
      if (typingDelay && !hasDelayed) {
        setHasDelayed(true)
        setIsMessagePending(false)
      }
    }, typingDelay)
    return () => clearTimeout(timer)
  }, [])

  const classes = useStyles()

  return (
    <Box
      onClick={handleClick}
      className={
        direction === "outgoing" ? classes.alignRight : classes.alignLeft
      }
    >
      <Box
        className={`${classes.messageBox} ${
          direction === "outgoing"
            ? classes.outgoingMessage
            : classes.incomingMessage
        } ${clickable && !clicked ? classes.clickable : ""}`}
      >
        <Box className={classes.messageContent}>
          {isMessagePending ? <TypingIndicator /> : children}
        </Box>
      </Box>
    </Box>
  )
}

ChatMessage.propTypes = {
  direction: PropTypes.oneOf(["outgoing", "incoming"]).isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default ChatMessage
