import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import TextField from "@material-ui/core/TextField"
import RemoveIcon from "@material-ui/icons/Remove"

import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import InputAdornment from "@material-ui/core/InputAdornment"

import { makeStyles } from "@material-ui/core/styles"
import colors from "../../theme/colors"

const useStyles = makeStyles(theme => ({
  root: {
    padding: "0.5rem 1rem",
    width: "100%",
  },
  clickable: {
    cursor: "pointer",
  },
  alignLeft: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  alignRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  fields: {
    display: "flex",
    alignItems: "center",
  },
  inputBox: {
    "& .MuiInputBase-root": {
      /* Hacking in style override for the moment */
      border: `1px solid ${colors.blue[1]}`,
      borderRadius: "6px 0 6px 6px !important",
      padding: "0 0.25rem",
      "& .MuiInputBase-input": {
        padding: "0.25em 0em !important",
      },
      "& svg": {
        height: "0.6em",
        width: "0.6em",
      },
      fontSize: "0.7rem",
      "@media (min-height: 500px)": {
        fontSize: "0.9rem",
      },
    },
  },
  rangeIcon: {
    fontSize: "1.5rem",
  },
  messageContent: {
    padding: "0.15em 0.75em",
    fontSize: "0.7rem",
    "& span": {
      fontSize: "0.7rem",
    },
    "@media (min-height: 500px)": {
      padding: "0.25em 1em",
      fontSize: "0.9rem",
      "& span": {
        fontSize: "0.9rem",
      },
    },
  },
  outgoingMessage: {
    color: colors.base.white,
    backgroundColor: colors.blue[1],
    border: `1px solid ${colors.blue[1]}`,
    borderRadius: "6px 0 6px 6px",
    "&$clickable": {
      "&:hover": {
        borderColor: colors.blue[0],
        background: colors.blue[0],
      },
    },
  },
}))

const ChatAutocompleteInput = ({ onClick, type, placeholder, options }) => {
  const [min, setMin] = useState("")
  const [max, setMax] = useState("")
  const [clicked, setClicked] = useState(false)
  const clickable = !!onClick

  const handleMinInput = e => {
    const val = formatValue(e.target.value, 5)
    setMin(val)
  }

  const handleMaxInput = e => {
    const val = formatValue(e.target.value, 5)
    setMax(val)
  }

  function formatValue(value, maxLength) {
    // Format input:
    // 1. Remove prefixing zero
    // 2. Ignore non-numeric input
    // 3. Enforce maximum length

    // By default, the field contains an empty string.
    if (value === "") return value

    // 1 & 2
    if (value) {
      let formatedValue = `${value}`.replace(/^0+/, "").replace(/[^0-9]/g, "")
      // 3
      if (formatedValue > maxLength) {
        formatedValue = formatedValue.substr(0, maxLength)
      }
      // Update value
      return formatedValue || "0"
    } else {
      // If value is in another state, return 0
      return "0"
    }
  }

  const handleSubmit = () => {
    if (!min && !max) return

    if (!clicked) {
      setClicked(true)

      const value = {
        sizeMin: min,
        sizeMax: max,
      }

      const valueString =
        min && max
          ? `${min} - ${max} m2`
          : !min && max
          ? `< ${max} m2`
          : min && !max
          ? `${min}+ m2`
          : ""

      if (!!onClick) {
        onClick(value, valueString)
      }
    }
  }

  const classes = useStyles()

  return (
    <Grid
      container
      direction="row"
      justify="center"
      alignItems="center"
      spacing={1}
      className={classes.root}
    >
      <Grid item xs={9} className={classes.fields}>
        <TextField
          className={classes.inputBox}
          onChange={handleMinInput}
          placeholder="min"
          variant="outlined"
          value={min}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                m<sup>2</sup>
              </InputAdornment>
            ),
          }}
        />
        <RemoveIcon className={classes.rangeIcon} />
        <TextField
          className={classes.inputBox}
          onChange={handleMaxInput}
          placeholder="max"
          variant="outlined"
          value={max}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                m<sup>2</sup>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={3}>
        <Box onClick={handleSubmit}>
          <Box
            className={`${classes.messageBox} ${classes.outgoingMessage} ${
              clickable && !clicked ? classes.clickable : ""
            }`}
          >
            <Box className={classes.messageContent}>OK</Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  )
}

ChatAutocompleteInput.propTypes = {
  direction: PropTypes.oneOf(["outgoing", "incoming"]).isRequired,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default ChatAutocompleteInput
